import Navbar from "./SharedComponents/Navbar/Navbar";
import InAppRouter from "./SharedComponents/Router/InAppRouter";

export default function App() {
  return (
    <div
      className="
							max-h-[768px] w-full grid justify-center gap-[16px] h-full
							grid-cols-[repeat(4,_minmax(0,_100%))]
							md:grid-cols-[repeat(8,_minmax(0,_100%))] md:mx-6
							lg:grid-cols-[repeat(16,_minmax(0,_48px))] lg:max-w-[1200px] lg:mx-auto lg:my-0
							xl:max-w-[1680px]
							"
    >
      <Navbar />
      <div
        className="
				col-span-4 mx-6
				md:col-span-6 md:mx-0
				lg:col-span-12
				"
      >
        <InAppRouter></InAppRouter>
      </div>
    </div>
  );
}
