import React from 'react';
import './index.css';
import Bootstraper from './Bootstraper';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Bootstraper />);

reportWebVitals(sendToVercelAnalytics);
