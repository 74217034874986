import { useGeneralStore } from "../DataSource/GeneralStore";
import { shallow } from "zustand/shallow";

export function useGetLogged() {
  return useGeneralStore((state) => state.isLoggedIn, shallow);
}

export function useSetLogged() {
  return useGeneralStore((state) => state.changeLogged, shallow);
}

export function useGetSupabaseClient() {
  return useGeneralStore((state) => state.supabase, shallow);
}

export function useGetUser() {
  return useGeneralStore((state) => state.user, shallow);
}

export function useSetUser() {
  return useGeneralStore((state) => state.changeUser, shallow);
}
