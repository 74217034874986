import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { Button } from "./Button";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
	active?: boolean;
  className?: string;
}; 

export const IconButton = ({ children, active, className, ...other }: Props) => {
  return <Button active={active} className={`flex flex-row flex-wrap gap-2 ${className ?? ''}`} {...other}>{children}</Button>;
};
