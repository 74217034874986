import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { IconButton } from "../../Dumb/Button/IconButton";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  notifications?: number;
	active?: boolean;
  className?: string;
};

export const NotificationButton = ({
  children,
  className,
	active,
  notifications,
	...other
}: Props) => {
  return (
    <IconButton active={active} className={`${className ?? ""}`} {...other}>
      {children}
      {notifications != null ? (
        <div className="aspect-square flex justify-center items-center w-fit rounded-full text-blue-900 bg-blue-200 text-xs min-w-[20px]">
          {notifications}
        </div>
      ) : null}
    </IconButton>
  );
};
