import React from "react";
import logo from "../../Assets/logo.svg"

export const Logo = () => {
  return (
    <div>
      <div className="flex flex-row flex-none gap-x-3">
        <img src={logo} alt="" className="w-7 xl:w-8" />
        <h1 className="font-bold self-center text-sm xl:text-xl">Tech Ease</h1>
      </div>
    </div>
  );
};
