import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetLogged } from '../Data/Repository/GeneralStoreRepository';

export default function AuthGuard() {
  const navigate = useNavigate();
	const isLoggedIn = useGetLogged();

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/auth")
		} else {
			navigate("/");
		}
	}, [isLoggedIn]);

	return <></>;
}

