import { Routes, Route } from "react-router-dom";
import Dashboard from "../../Presentation/Dashboard/View";

export default function InAppRouter() {
	return (
		<Routes>
			<Route path="/" element={<Dashboard />} />
		</Routes>
	)
}
