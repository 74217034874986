import { useNavigate } from "react-router-dom";
import { BASIC_USER_INFO } from "../../../Constants/ApiTable";
import { useGetSupabaseClient } from "../../../Data/Repository/GeneralStoreRepository";
import { OK, SIGN_IN_UNCOMPLETED, USER_NOT_FOUND } from "./ResponseCodes";
import { LoginFormData, ValidateOTPData } from "./Type";

// TODO move supabase logic to SupabaseAuthRepository

export default function AuthUseCase() {
  const supabase = useGetSupabaseClient();
  const navigate = useNavigate(); 

  async function logout() {
    // logout from supabase
    supabase.auth.signOut();
    navigate("/login");
  }

  async function checkIfUserExists(data: LoginFormData) {
    let { data: info, error } = await supabase
      .from(BASIC_USER_INFO)
      .select("*")
      .eq("name", data.name)
      .eq("phone_number", data.phonePrefix + data.phoneNumber)
      .single();

    console.log("---------");
    console.log("CHECK USER EXISTS: ");
    console.log(info);
    console.log(error);
    console.log("---------");
    if (error != null || info == null) {
      return false;
    }
    return true;
  }

  async function updateUserIdInBasicInfo(userId: string, data: LoginFormData) {
    const { data: info, error } = await supabase
      .from(BASIC_USER_INFO)
      .update({ user_id: userId })
      .eq("name", data.name)
      .eq("phone_number", data.phonePrefix + data.phoneNumber);

    console.log("---------");
    console.log("UPDATE USER ID: ");
    console.log(info);
    console.log(error);
    console.log("---------");
    return error != null;
  }

  async function signIn(data: LoginFormData, createUser: boolean) {
    await supabase.auth.signInWithOtp({
      phone: data.phonePrefix + data.phoneNumber,
      options: {
        shouldCreateUser: createUser,
      },
    });
  }

  async function authenticate(data: LoginFormData) {
    console.log("---- AUTHENTICATE USE CASE");
    console.log(data);
    const doesUserExist = await checkIfUserExists(data);
    if (doesUserExist == false) {
      return USER_NOT_FOUND;
    }
    await signIn(data, doesUserExist);
    return OK;
  }

  async function validateOtp(data: LoginFormData, otpData: ValidateOTPData) {
    const MAX_REPETED_REQUESTS = 3;
    let { data: info, error } = await supabase.auth.verifyOtp({
      phone: data.phonePrefix + data.phoneNumber,
      token: otpData.token,
      type: "sms",
    });

    console.log("---------");
    console.log("VALIDATE OTP: ");
    console.log(info);
    console.log(error);
    console.log("---------");
    // TODO: Do not block main thread
    for (
      let requests = 0;
      requests < MAX_REPETED_REQUESTS &&
      !(await updateUserIdInBasicInfo(info.user.id, data));
      ++requests
    );

    if (error != null) {
      return SIGN_IN_UNCOMPLETED;
    }
    return OK;
  }

  return {
    authenticate,
    validateOtp,
    logout,
  };
}
