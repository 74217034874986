import React, { useRef, useEffect } from "react";
import { useGetUser } from "../../../Data/Repository/GeneralStoreRepository";

type Props = {
  className: string;
};

export default function LandbotChat({ className }: Props) {
  const containerRef = useRef(null);
  const user = useGetUser();

  useEffect(() => {
    console.log("---- LANDBOT INITIALIZATION");
    if (user == null) {
      return;
    }
    const Landbot = (window as any).Landbot;
    const _landbot = new Landbot.Container({
      container: containerRef.current,
      configUrl:
        "https://storage.googleapis.com/landbot.online/v3/H-1494442-9TQ1F6A9CO8R7549/index.json",
      customData: {
        user_info: {
          id: user.id,
          name: user.name,
					phone_number: user.phone_number,
					company_id: user.company_id,
        },
      },
    });

    return () => _landbot.destroy();
  }, [containerRef, user]);

  return <div className={className} ref={containerRef} />;
}
