import React from "react";
import { Logo } from "../../SharedComponents/Logo/Logo";
import Background from "./Components/Background";
import CountryDropdown from "./Components/CountryDropdown";
import useViewModel from "./ViewModel";

export default function Auth() {
  const viewModel = useViewModel();
  return (
    <Background>
      <div className="h-full w-full relative z-10">
        <div className="container p-4 md:p-8 2xl:p-16 h-full w-full mx-auto flex flex-col flex-none items-center">
          <Logo />
          <div className="h-full flex items-center">
            <div className="h-fit md:min-w-[512px] p-14 2xl:p-28 flex flex-col gap-y-9 bg-white drop-shadow-xl rounded-xl place-content-center">
              <h1 className="text-5xl font-bold self-center">Login</h1>
              <h3 className="text-xl self-center">Let's setup your account</h3>

              <div className="items-start flex flex-col gap-y-2">
                {viewModel.hasReceivedOTP ? (
                  <form
                    className="container flex flex-col gap-y-11"
                    onSubmit={viewModel.validateOTP}
                  >
                    <div className="flex flex-col gap-y-1">
                      <p className="text-md font-bold">Insert code here</p>
                      <div className="container p-4 rounded-xl bg-gray-100 flex flex-row gap-x-2">
                        <input
                          name="otp"
                          type="text"
                          placeholder="Insert SMS code"
                          className="bg-gray-100 outline-0 w-full"
                        />
                      </div>
                    </div>
                    <button className="container rounded-xl bg-black text-white p-2">
                      Next
                    </button>
                  </form>
                ) : (
                  <form
                    className="container flex flex-col gap-y-11"
                    onSubmit={viewModel.authenticate}
                  >
                    <div className="container flex flex-col gap-y-6">
                      <div className="flex flex-col gap-y-1">
                        <p className="text-md font-bold">Phone</p>
                        <div className="container p-4 rounded-xl bg-gray-100 flex flex-row gap-x-2">
                          {/* <CountryDropdown name="phone_prefix" /> */}
                          {/* <div className="bg-gray-200 w-[2px] h-[25px]"></div> */}
                          <input
                            name="phone_number"
                            type="tel"
                            placeholder="Your phone number"
                            className="bg-gray-100 outline-0 w-full"
                          />
                        </div>
                      </div>

                      {/* Name input */}
                      <div className="flex flex-col gap-y-1">
                        <p className="text-md font-bold">Name</p>
                        <div className="container p-4 rounded-xl bg-gray-100 flex flex-row gap-x-2">
                          <input
                            name="name"
                            type="text"
                            placeholder="Tell us your name"
                            className="bg-gray-100 outline-0 w-full"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Save button */}
                    <button className="container rounded-xl bg-black text-white p-2">
                      Next
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Background>
  );
}
