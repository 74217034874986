import React, { useEffect } from "react";
import "./Bootstraper.css";
import { BrowserRouter } from "react-router-dom";
import AuthGuard from "./Guard/AuthGuard";
import Router from "./SharedComponents/Router/Router";
import {
  useGetSupabaseClient,
  useSetLogged,
  useSetUser,
} from "./Data/Repository/GeneralStoreRepository";
import useUser from "./Domain/UseCase/User/UserUseCase" 

export default function Bootstraper() {
  const setUser = useSetUser();
  const setLogged = useSetLogged();
  const supabase = useGetSupabaseClient();
  const userUseCase = useUser();

  useEffect(() => {
    async function init() {
      const sessionResponse = await supabase.auth.getSession();
      const supabaseSession = sessionResponse.data?.session;

      if (supabaseSession == null) {
        setLogged(false);
        return;
      }

      const user = await userUseCase.fetchUser();
      if (user == null) {
        return;
      }
      setUser(user);
      setLogged(true);
    }
    init();
  }, []);

  return (
    <BrowserRouter>
      <AuthGuard></AuthGuard>
      <Router></Router>
    </BrowserRouter>
  );
}
