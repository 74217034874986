import { create } from "zustand";
import { createClient } from "@supabase/supabase-js";
import { Database } from "../../Types/supabase";

const supabaseUrl = "https://phevkacaazzvrmcecmgu.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBoZXZrYWNhYXp6dnJtY2VjbWd1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY3Mzk5NTEsImV4cCI6MTk5MjMxNTk1MX0.CzuOW0W_cuj_KcybSkJyndoEMBppagATzL1_lN0m4Ek";
const supabase = createClient<Database>(supabaseUrl, supabaseKey);

export interface User {
  id: string;
  name: string;
	phone_number: string;
	company_id: string;
}

export interface GeneralState {
  isLoggedIn: boolean;
  supabase: any;
  changeLogged: (value: boolean) => void;
  user: User;
  changeUser: (value: any) => void;
}

export const useGeneralStore = create<GeneralState>()((set) => ({
  isLoggedIn: true,
  supabase: supabase,
  changeLogged: (value: boolean) => set(() => ({ isLoggedIn: value })),
  user: null,
  changeUser: (value: User) => set(() => ({ user: value })),
}));
