import background from "../../../Assets/login-background.svg";

export default function Background({ children }) {
  return (
    <div className="relative h-screen w-screen">
      <img src={background} alt="" className="absolute h-full w-full inset-0 object-cover" />
      {children}
    </div>
  );
	
}
