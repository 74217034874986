import { useGetSupabaseClient } from "../../../Data/Repository/GeneralStoreRepository";

export default function UserUseCase() {
  const supabase = useGetSupabaseClient();

  async function fetchUser() {
    const userId = await fetchUserIdFromSupabaseSession();

    console.log("----- USER ID");
    console.log(userId);
    if (userId === null) {
      return null;
    }

    const response = await supabase
      .from("basic_user_info")
      .select("user_id, name, phone_number, company_id")
      .eq("user_id", userId)
      .single();
    console.log("----- GET USER");
    console.log(response);
    return {
      id: response.data.user_id,
      name: response.data.name,
      phone_number: response.data.phone_number,
			company_id: response.data.company_id,
    };
  }

  async function fetchUserIdFromSupabaseSession() {
    try {
      const response = await supabase.auth.getSession();
      return response.data.session.user.id;
    } catch (error) {
      return null;
    }
  }

  return {
    fetchUser,
  };
}
