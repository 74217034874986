import React from "react";

type Props = {
  isOpen: boolean;
};

export const Overlay = ({ isOpen }: Props) => {
  return isOpen ? (
    <div className="fixed top-0 left-0 w-screen h-screen z-10 md:hidden overflow-hidden bg-black/50"></div>
  ) : null;
};
