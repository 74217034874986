import React, { ButtonHTMLAttributes, ReactNode } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
	active?: boolean;
  className?: string;
}; 

export const Button = ({ className, active, children, ...other }: Props) => {
  return ( 
		<button 
			className={`${active != null && active == true ? 'bg-gray-200' : 'bg-white'} w-full outline-none flex items-center font-medium p-1.5 rounded-lg hover:bg-gray-200 duration-150
									${className ?? ''}`}
			{...other}
		>
				{children}
		</button>
	);
};
