import { Routes, Route } from 'react-router-dom'
import App from '../../App';
import Auth from '../../Presentation/Auth/View';

export default function Router() {
  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
			<Route path="/*" element={<App />} />
    </Routes>
  );
}
