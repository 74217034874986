import React, { useState } from "react";
import { NotificationButton } from "./Components/NotificationButton";
import { Logo } from "../Logo/Logo";
import { IconButton } from "../Dumb/Button/IconButton";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/solid";
import {
  BellIcon,
  UserIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  ClipboardDocumentCheckIcon,
  InformationCircleIcon,
	ChatBubbleLeftIcon
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { ERoutes } from "../../Constants/Routes";
import useAuthentication from "../../Domain/UseCase/Auth/AuthUseCase";
import { Overlay } from "../Dumb/Overlay/Overlay";

type Props = {
  className?: string;
};

export default function Navbar({ className }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const authUseCase = useAuthentication();
  const [isOpen, ChangeIsOpen] = useState<boolean>(false);

  function onOpenClickHandler(): void {
    ChangeIsOpen((prevOpen) => !prevOpen);
  }

  function onChangeRouteHandler(path: string): void {
    navigate(path);
    onOpenClickHandler();
  }

  function isRouteActive(path: string): boolean {
    return location.pathname == path;
  }

  return (
    <>
      <div
        className="
					flex flex-row justify-start items-center w-screen h-12 absolute top-0 left-0 bg-white
					md:hidden
				"
      >
        <IconButton onClick={onOpenClickHandler} className="!w-fit">
          <Bars3Icon className="w-6 aspect-square" />
        </IconButton>
      </div>

      <Overlay isOpen={isOpen} />

      <div
        className={`flex-col gap-3 bg-white rounded-xl ${
          isOpen ? "w-2/3 p-8" : "w-0 p-0"
        } top-0 left-0 z-50
					transition-all overflow-hidden
					absolute min-w-0 max-w-[256px] h-full flex
					md:w-full md:col-span-2 md:static md:visible md:p-8
					lg:col-span-4
					${className ?? ""}`}
      >
        <div
          className="py-6 px-2 cursor-pointer w-full flex flex-row justify-between items-center"
          onClick={() => onChangeRouteHandler(ERoutes.HOME)}
        >
          <Logo />
          <IconButton className="!w-fit !p-0" onClick={onOpenClickHandler}>
            <XMarkIcon className="w-5 aspect-square visible md:hidden" />
            <ChevronDoubleLeftIcon className="w-5 aspect-square hidden md:block" />
          </IconButton>
        </div>
        <div className="flex flex-col flex-grow justify-between items-center content-start flex-wrap w-full">
          <div className="w-full">
            <IconButton
              active={isRouteActive(ERoutes.HOME)}
              onClick={() => onChangeRouteHandler(ERoutes.HOME)}
            >
              <ChatBubbleLeftIcon className="w-4 aspect-square text-gray-400" />
              Chat 
            </IconButton>

            <NotificationButton
              active={isRouteActive(ERoutes.NOTIFICATIONS)}
              onClick={() => onChangeRouteHandler(ERoutes.NOTIFICATIONS)}
            >
              <BellIcon className="w-4 aspect-square text-gray-400" />
              Notificari
            </NotificationButton>
            <NotificationButton
              active={isRouteActive(ERoutes.TICKET_HISTORY)}
              onClick={() => onChangeRouteHandler(ERoutes.TICKET_HISTORY)}
            >
              <ClipboardDocumentCheckIcon className="w-4 aspect-square text-gray-400" />
              Istoric tichete
            </NotificationButton>
            <IconButton
              active={isRouteActive(ERoutes.KNOWLEDGE_BASE)}
              onClick={() => onChangeRouteHandler(ERoutes.KNOWLEDGE_BASE)}
            >
              <InformationCircleIcon className="w-4 aspect-square text-gray-400" />
              Baza de cunostiinte
            </IconButton>
          </div>
          <div className="w-full">
            <IconButton
              active={isRouteActive(ERoutes.PROFILE)}
              onClick={() => onChangeRouteHandler(ERoutes.PROFILE)}
            >
              <UserIcon className="w-4 aspect-square text-gray-400" />
              Profil
            </IconButton>
            <IconButton
              active={isRouteActive(ERoutes.SETTINGS)}
              onClick={() => onChangeRouteHandler(ERoutes.SETTINGS)}
            >
              <Cog6ToothIcon className="w-4 aspect-square text-gray-400" />
              Setari
            </IconButton>
            <IconButton
              onClick={() => {
                authUseCase.logout();
                onOpenClickHandler();
              }}
            >
              <ArrowLeftOnRectangleIcon className="w-4 aspect-square text-gray-400" />
              Logout
            </IconButton>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
