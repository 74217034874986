import { useEffect, useState } from "react";
import useAuthentication from "../../Domain/UseCase/Auth/AuthUseCase";
import useUser from "../../Domain/UseCase/User/UserUseCase";
import { OK } from "../../Domain/UseCase/Auth/ResponseCodes";
import { useNavigate } from "react-router-dom";
import {
  useSetLogged,
	useSetUser,
} from "../../Data/Repository/GeneralStoreRepository";
import { LoginFormData } from "../../Domain/UseCase/Auth/Type";
import { ValidateOTPData } from "../../Domain/UseCase/Auth/Type";

export default function AuthModel() {
  const navigate = useNavigate();
  const setLogged = useSetLogged();
	const setUser = useSetUser();
  const authencationUseCase = useAuthentication();
  const userUseCase = useUser();
  const [hasReceivedOTP, changeHasReceivedOTP] = useState<boolean>(false);
  const [loginData, changeLoginData] = useState<LoginFormData>(null);

  useEffect(() => {
    async function authenticateUser() {
      if (loginData === null) {
        return;
      }
      const result = await authencationUseCase.authenticate(loginData);
      if (result === OK) {
        changeHasReceivedOTP(true);
      } else {
        alert("Could not complete request");
      }
    }
    authenticateUser();
  }, [loginData]);

  async function authenticate(event) {
    event.preventDefault();
    changeLoginData({
      phonePrefix: event.target.phone_prefix?.value ?? '4',
      phoneNumber: event.target.phone_number.value,
      name: event.target.name.value,
    });
  }

  async function validateOTP(event) {
    event.preventDefault();
    const data: ValidateOTPData = { token: event.target.otp.value };
    const result = await authencationUseCase.validateOtp(loginData, data);
    if (result !== OK) {
      alert("Could not complete request: signIn");
      return;
    }
    const user = await userUseCase.fetchUser();

    if (user === null) {
      alert("Could not complete request: user");
      return;
    }
    navigate("/");
		setUser(user);
    setLogged(true);
  }

  return {
    authenticate,
    validateOTP,
    hasReceivedOTP,
  };
}
